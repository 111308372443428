<template>
  <notification-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t('_.title') }}</span>
    </template>
    <p v-t:_.statusMessage="{shopItemTitle}" />
    <p class="msg" v-t:_.detailMessage="{shopItemTitle, eventResultDateTime}" />
    <p class="msg" v-t:_.otherMessage></p>
  </notification-item>
</template>

<script>
import Specific from '@shared/types/Specific';
import NotificationItem from '@shared/components/notification/NotificationItem.vue';

export default {
  name: 'ShopLotteryItemBuyItem',
  components: { NotificationItem },
  lexicon: 'shared.notification.SHOP_LOTTERY_ITEM_BUY',
  props: {
    message: Specific,
  },
  computed: {
    /** @type {NotificationShopData} */
    shopData() {
      return this.message.data;
    },
    shopItemTitle() {
      return this.shopData.shopItemTitle;
    },
    eventResultDateTime() {
      return this.$date(this.shopData.eventResultDateTime);
    },
  },
  methods: {
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

</style>
